import Pusher from 'pusher-js'

let pusherInstance
let broadcastChannel

export default ({ app }, inject) => {
  const getPusher = (config) => {
    if (!broadcastChannel) {
      broadcastChannel = new BroadcastChannel('pusher-connection')
    }

    if (!pusherInstance) {
      pusherInstance = new Pusher(process.env.PUSHER_KEY, config)
      // Notify other tabs that a Pusher instance is active
      broadcastChannel.postMessage('connected')
    }

    // Listen for messages from other tabs
    broadcastChannel.onmessage = (event) => {
      if (event.data === 'connected') {
        console.log('Pusher already initialized in another tab.')
      }
    }

    return pusherInstance
  }

  const disconnectPusher = () => {
    if (pusherInstance) {
      pusherInstance.disconnect()
      console.log('Pusher disconnected')
    }
  }

  inject('pusher', {
    getPusher,
    disconnectPusher
  })
}
