import { mapActions, mapState, mapMutations } from 'vuex'
import Compressor from 'compressorjs'
export default {
  data () {
    return {
      defaultImageProduct: '/img/default/default_product_image.png',
      defaultImageBanner: '/img/default/banner/default_landscape_image.jpg',
      defaultImageBannerFlashsale:
        '/img/home/flashsale/banner_flashsale_default.png',
      defaultImageStore: '/img/default/default_store_logo.png',
      defaultImageBrand: '/img/default/default_brand_logo.png',
      defaultCoverStore: '/img/default/default_cover_store.png',
      defaultCoverBrand: '/img/default/default_cover_brand.png',
      defaultImageAvatarM: '/img/account/profile/default_avatar.png',
      defaultImageAvatarF: '/img/account/profile/default_avatar_female.png',
      defaultImageCC: '/img/icon/checkout/icon-card.svg'
    }
  },
  computed: {
    ...mapState('Voucher', ['voucherUsed']),
    isAffiliate () {
      if (this.$route.name) {
        const breakPath = this.$route.name.split('-')
        if (this.checkArray(breakPath) && breakPath[0] === 'affiliate') {
          return true
        }
      }

      return false
    }
  },
  methods: {
    ...mapActions('Checkout', ['getItemAvailability']),
    ...mapActions('Cart', ['getCartAvailability']),
    ...mapMutations('Voucher', ['setVoucherUsed']),
    checkActiveOnlyVoucher (checkoutAvailabilityVoucher = []) {
      if (checkoutAvailabilityVoucher && checkoutAvailabilityVoucher.length) {
        const voucherIds = checkoutAvailabilityVoucher.map((voucher) => {
          return {
            id: voucher.id,
            available: voucher.available
          }
        })

        const checkUnavailable = voucherIds.some((v) => {
          return v.available === false
        })

        if (checkUnavailable) {
          const onlyExist = this.voucherUsed.filter((selected) => {
            return voucherIds.some((v) => {
              return (selected.id === v.id) && v.available
            })
          })

          this.setVoucherUsed(onlyExist)

          if (onlyExist.length) {
            localStorage.voucher = JSON.stringify(onlyExist)
          } else {
            localStorage.removeItem('voucher')
          }
        }
      }
    },
  checkArray (arr) {
    return !!(arr && arr.length)
  },
  checkObject (obj) {
    return !!(obj && Object.keys(obj).length)
  },
  checkObjectKey (obj, key) {
    return Object.prototype.hasOwnProperty.call(obj, key)
  },
  compareArrays (a, b) {
    return JSON.stringify(a) === JSON.stringify(b)
  },
  getUniqueList (arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()]
  },
    setQuery (queries = {}) {
      if (queries && Object.keys(queries).length) {
        this.$router.push({
          query: {
            ...this.$route.query,
            ...queries
          }
        })
      } else {
        this.$router.push({
          query: null
        })
      }
    },
    checkCommonValueArrays (a, b) {
      return a.some(value => b.includes(value))
    },
    findCommonValues (arr1, arr2) {
      const set1 = new Set(arr1);
      const commonValues = arr2.filter(item => set1.has(item));
      return commonValues;
    },
    getPageFromUrl (url, chars = '=') {
      return url ? url.substr(url.lastIndexOf(chars) + 1, url.length) : null
    },
    slugtify (str) {
      str = str.replace(/^\s+|\s+$/g, '') // trim
      str = str.toLowerCase()

      // remove accents, swap ñ for n, etc
      const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
      const to = 'aaaaeeeeiiiioooouuuunc------'
      for (let i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
      }

      str = str
        .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-') // collapse dashes

      return str
    },
    strLimit (str, max) {
      const strArr = str.split(' ')
      let strFilter = ''

      for (let index = 0; index < strArr.length; index++) {
        const strItem = strFilter + ' ' + strArr[index]

        if (strItem.length <= max) {
          strFilter = strFilter + ' ' + strArr[index]
        } else {
          break
        }
      }

      if (str.length > strFilter.length) {
        strFilter = strFilter + '...'
      }

      return strFilter
    },
    formatRupiah (angkas, prefix) {
      if (!angkas || angkas < 0) {
        angkas = 0
      }
      const temp = []
      const data = angkas.toString().split('-')
      data.map((angka) => {
        temp.push(this.handleFormat(angka, prefix))
      })
      return temp.join(' - ')
    },
    handleFormat (amount, prefix) {
      // Remove any non-digit characters except comma
      const numberString = amount.toString().replace(/[^,\d.]/g, '')
      const [integerPart, fractionalPart] = numberString.split('.')

      // Calculate the initial segment length
      const initialSegmentLength = integerPart.length % 3

      // Format the integer part with dots as thousand separators
      let rupiah = integerPart.substr(0, initialSegmentLength)
      const thousands = integerPart.substr(initialSegmentLength).match(/\d{3}/gi)

      if (thousands) {
        const separator = initialSegmentLength ? '.' : ''
        rupiah += separator + thousands.join('.')
      }

      // Include the fractional part if it exists
      rupiah = fractionalPart !== undefined ? `${rupiah},${fractionalPart}` : rupiah

      // Add the prefix if provided
      return prefix ? `${prefix}${rupiah}` : rupiah
    },
    formatRupiahFlashSale (angka, prefix) {
      const ribuanArray = angka.split('').reverse()
      const ribuan = []
      let i = 0
      let temp = ''
      const sisa = angka.substring(0, ribuanArray.length % 3)
      ribuanArray.forEach((r) => {
        if (i !== 2) {
          i++
          temp += r
        } else {
          i = 0
          temp += r
          temp = '.' + temp
          const tempData = temp.split('').reverse()
          ribuan.push(tempData.join(''))
          temp = ''
        }
      })
      let output = ''
      ribuan.reverse().forEach((r) => {
        output += r
      })
      return (
        'Rp' + (sisa ? sisa + '.' : '') + output.substring(0, output.length - 1)
      )
    },
    validateYouTubeUrl (url) {
      if (url !== undefined || url !== '') {
        const regExp =
          '/^.*(youtu.be/|v/|u/w/|embed/|watch?v=|&v=|?v=)([^#&?]*).*/'
        const match = url.match(regExp)
        if (match && match[2].length === 11) {
          return {
            id: this.youtube_parser(url),
            valid: true
          }
        } else {
          return {
            id: this.youtube_parser(url),
            valid: false
          }
        }
      } else {
        return {
          id: this.youtube_parser(url),
          valid: false
        }
      }
    },
    youtube_parser (url) {
      const regExp =
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
      const match = url.match(regExp)
      return match && match[7].length === 11 ? match[7] : ''
    },
    countdownTime (date) {
      /*eslint-disable */
      let countdownTime =
        new Date(this.formatYMD(date)).getTime() -
        this.convertTZ(new Date()).getTime()

      if (Number.isNaN(countdownTime)) {
        countdownTime =
          new Date(this.formatYMD(date).replace(' ', 'T')).getTime() -
          this.convertTZ(new Date()).getTime()
      }

      return countdownTime < 0 ? 0 : countdownTime
    },
    getHours(date) {
      if (date instanceof Date && !isNaN(date)) {
        return new Date(date)
          .toISOString()
          .slice(11, 16)
      }

      return date.substr(11, 5)
    },
    getFSHours(tgl) {
      /*eslint-disable */
      const local = new Date()
      const server = this.convertTZ(local).getTime()
      const diff = server - local.getTime()
      let time = new Date(this.formatYMD(tgl)).getTime() - diff
      if (Number.isNaN(time)) {
        time = new Date(this.formatYMD(tgl).replace(' ', 'T')).getTime() - diff
      }
      return this.$dayjs(time).format('HH:mm')
    },
    formatYMD(date, separator = '-') {
      return (
        date.substr(6, 4) +
        separator +
        date.substr(3, 2) +
        separator +
        date.substr(0, 2) +
        date.substr(10, 9)
      )
    },
    formatDMY(date, separator = '-', reverse = false) {
      if (date instanceof Date && !isNaN(date)) {
        return new Date(date)
          .toISOString()
          .slice(0, 10)
          .split('-')
          .reverse()
          .join(separator)
      }

      if (reverse) {
        return (
          date.substr(0, 2) +
          separator +
          date.substr(3, 2) +
          separator +
          date.substr(6, 4)
        )
      }

      return (
        date.substr(8, 2) + separator + date.substr(5, 2) + separator + date.substr(0, 4)
      )
    },
    getMonthAndYear (date, separator = '-', reverse = false) {
      if (reverse) {
        return (
          date.substr(3, 2) +
          separator +
          date.substr(6, 4)
        )
      }

      return (
        date.substr(5, 2) + separator + date.substr(0, 4)
      )
    },
    convertTZ(date, tzString) {
      return new Date(
        (typeof date === 'string' ? new Date(date) : date).toLocaleString(
          'en-US',
          { timeZone: this.$config.utcServer }
        )
      )
    },
    generateRandomString(limit, type = null) {
      let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ!@#$%^&*()_+?><:{}[]'
      if (type === 'alphanumeric') {
        chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'
      }

      let string = ''

      for (let index = 0; index < limit; index++) {
        const randomNumber = Math.floor(Math.random() * chars.length)
        string += chars.substring(randomNumber, randomNumber + 1)
      }

      return string.toString()
    },
    toDataUrl(url) {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()
        xhr.onload = function () {
          const reader = new FileReader()
          reader.onloadend = function () {
            resolve(reader.result)
          }
          reader.onerror = (error) => reject(error)
          reader.readAsDataURL(xhr.response)
        }
        xhr.open('GET', url)
        xhr.responseType = 'blob'
        xhr.send()
      })
    },
    dataURLtoFile(dataurl, filename) {
      const arr = dataurl.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }

      return new File([u8arr], filename, { type: mime })
    },
    toBase64(file) {
      const reader = new FileReader()

      return new Promise((resolve, reject) => {
        reader.readAsDataURL(file)
        reader.onload = () => {
          resolve(reader.result)
        }
        reader.onerror = function (error) {
          reject(error)
        }
      })
    },
    compressImage(file) {
      const _file = new Promise((resolve, reject) => {
        new Compressor(file, {
          quality: 0.6,
          convertSize: 3000000,
          convertTypes: 'image/png',
          success(result) {
            resolve({ status: 200, file: result })
          },
          error() {
            reject({ status: 500, file: {} })
          }
        })
      })
      return _file
    },
    getDefaultAvatar(gender) {
      let img = '/img/account/profile/default_avatar'
      if (gender === 'female') {
        img += '_female'
      }
      return img + '.png'
    },
    formatDate(date, withoutDay = false) {
      const monthNames = [
        'Januari',
        'Februari',
        'Maret',
        'April',
        'Mei',
        'Juni',
        'Juli',
        'Agustus',
        'September',
        'Oktober',
        'November',
        'Desember'
      ]

      if (withoutDay) {
        return (
          monthNames[Number(date.substr(3, 2)) - 1] +
          ' ' +
          date.substr(6, 4)
        )
      }

      return (
        date.substr(0, 2) +
        ' ' +
        monthNames[Number(date.substr(3, 2)) - 1] +
        ' ' +
        date.substr(6, 4)
      )
    },
    getStatusPesanan(status, method) {
      if (status === 'menunggu-pembayaran' || status === 'pre-order') {
        return ['Belum Bayar', 'red']
      } else if (
        status === 'bukti-pembayaran-ditolak' ||
        status === 'transaksi-dibatalkan'
      ) {
        return ['Dibatalkan', 'red']
      } else if (
        status === 'pembayaran-berhasil' ||
        status === 'menunggu-konfirmasi-pembayaran' ||
        status === 'pesanan-sedang-dikemas'
      ) {
        return ['Diproses', 'yellow']
      } else if (status === 'pesanan-telah-dikirim') {
        return [method === 'shipping' ? 'Dikirim' : 'Siap Diambil', 'blue']
      } else if (status === 'transaksi-selesai') {
        return ['Selesai', 'green']
      } else {
        return null
      }
    },
    async checkAvailibilities(
      cartId,
      storeId,
      addressId,
      courierCode,
      courierService,
      pas,
      voucherId = [],
      usePoint,
      credit_card
    ) {
      let resp = []
      try {
        const payload = {
          data: {
            cart_id: cartId,
            pickup_at_store: pas,
            use_paninti_point: usePoint
          }
        }
        if (storeId) {
          payload.data = Object.assign({
            ...payload.data,
            store_branch_id: storeId
          })
        }
        if (addressId) {
          payload.data = Object.assign({
            ...payload.data,
            user_address_id: addressId
          })
        }
        if (courierCode && courierService) {
          payload.data = Object.assign({
            ...payload.data,
            shipping_courier: {
              code: courierCode,
              service: courierService
            }
          })
        }
        if (voucherId && voucherId.length) {
          payload.data = Object.assign({
            ...payload.data,
            voucher_id: voucherId
          })
        }
        if (credit_card && Object.keys(credit_card).length) {
          payload.data = Object.assign({
            ...payload.data,
            ...credit_card
          })
        }

        if (this.$route.path === '/checkout') {
          resp = await this.getItemAvailability(payload)
        } else if (this.$route.path === '/cart') {
          resp = await this.getCartAvailability(payload)
        }

        if (resp.status === 200) {
          return resp
        }
      } catch (error) {
        this.$Swal.fire({
          icon: 'error',
          title: 'Oops, terjadi kesalahan',
          text: 'Mohon maaf atas ketidaknyamanan ini',
          heightAuto: false
        })
        console.log(error)
        return error
      }
    },
    initFilter(
      qbrands,
      qsearch,
      qcategory,
      qcolors,
      qsizes,
      qpriceLimit,
      qrating,
      qsort,
      qlocation
    ) {
      let brands, category, colors, sizes, priceLimit
      if (qbrands) {
        brands = qbrands.split('::')
        brands = brands.length ? brands : [qbrands]
      } else {
        brands = []
      }
      if (qcategory) {
        category = qcategory.split('::')
        category = category.length ? category : category.push(qcategory)
      } else {
        category = []
      }
      if (qcolors) {
        colors = qcolors.split('::')
        colors = colors.length ? colors : [qcolors]
      } else {
        colors = []
      }
      if (qsizes) {
        sizes = qsizes.split('::')
        sizes = sizes.length ? sizes : [qsizes]
      } else {
        sizes = []
      }

      if (qpriceLimit) {
        const temp = qpriceLimit.split('::')
        priceLimit = temp
      }
      const rating = qrating ? qrating.split('::').reverse() : [5]
      const search = qsearch || ''
      const sort = qsort || ''
      const location = qlocation ? qlocation.split('::') : []

      return {
        brands,
        search,
        category,
        colors,
        sizes,
        priceLimit,
        rating,
        sort,
        location
      }
    },
    getOS(window) {
      if (process.client) {
        if (window && Object.keys(window).length) {
          var userAgent = window.navigator.userAgent,
            platform =
              window.navigator?.userAgentData?.platform ||
              window.navigator.platform,
            macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
            windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
            iosPlatforms = ['iPhone', 'iPad', 'iPod'],
            os = null

          if (macosPlatforms.indexOf(platform) !== -1) {
            os = 'ios'
          } else if (iosPlatforms.indexOf(platform) !== -1) {
            os = 'ios'
          } else if (windowsPlatforms.indexOf(platform) !== -1) {
            os = 'Windows'
          } else if (/Android/.test(userAgent)) {
            os = 'Android'
          } else if (/Linux/.test(platform)) {
            os = 'Linux'
          }
        }
      }

      return os || ''
    },
    modelPayload(
      brands,
      search,
      category,
      colors,
      sizes,
      priceLimit,
      rating,
      sort,
      location
    ) {
      const payload = {}
      if (brands) {
        payload.brands = brands
      }
      if (search) {
        payload.search = search
      }
      if (category) {
        payload.categories = category
      }
      if (priceLimit !== '0::10000000' && priceLimit) {
        payload.priceLimit = priceLimit
      }
      if (rating) {
        payload.rating = rating
      }
      if (colors) {
        payload.colors = colors
      }
      if (sizes) {
        payload.sizes = sizes
      }
      if (sort) {
        payload.sort = sort
      }
      if (location) {
        payload.city = location
      }
      return payload
    },
    getPiece(count, limit) {
      if (count > limit) {
        return limit - (count % limit) !== limit ? limit - (count % limit) : 0
      } else {
        return limit - count
      }
    },
    getThumbnailImage(files, type) {
      let img = ''

      if (type === 'store' || type === 'brand') {
        img = `/img/default/default_${type}_logo.png`
      } else if (img === 'category') {
        img = '/img/default/default_product_image.png'
      } else {
        img = '/img/default/default_product_image.png'
      }

      if (files !== undefined && files.length) {
        const temp = files.filter((file) => {
          if (type === 'store' || type === 'brand') {
            return file.variant === 'original' && file.name === 'logo'
          }
          return (
            file.variant === 'thumbnail' &&
            (file.name === type || file.name === 'logo')
          )
        })

        if (temp.length) {
          img = temp[0].value + '.webp'
        }
      }

      return img
    },
    getImageOriginal(files, type) {
      let img = ''
      if (type === 'store' || type === 'brand') {
        img = `/img/default/default_${type}_logo.png`
      } else if (img === 'category') {
        img = '/img/default/default_product_image.png'
      } else {
        img = '/img/default/default_product_image.png'
      }
      const image = files.filter((file) => {
        return file.name === type && file.variant === 'original'
      })
      if (image.length) {
        return image[0].value + '.webp'
      }
      return img
    },
    clearDataCart() {
      if (localStorage.cartItem) {
        localStorage.removeItem('cartItem')
      }
      if (localStorage.note) {
        localStorage.removeItem('note')
      }
      if (localStorage.getItem('voucher')) {
        localStorage.removeItem('voucher')
      }
    },
    getDefaultImgProduct(event) {
      if (event.target.src && event.target.src.indexOf('.webp') > -1) {
        event.target.src = event.target.src.replace('.webp', '')
      } else {
        event.target.src = this.defaultImageProduct
      }
    },
    getDefaultImgBanner(event, flashsale = false) {
      if (event.target.src && event.target.src.indexOf('.webp') > -1) {
        event.target.src = event.target.src.replace('.webp', '')
      } else {
        event.target.src = flashsale
          ? this.defaultImageBannerFlashsale
          : this.defaultImageBanner
      }
    },
    getDefaultImgAvatar(event, type) {
      if (event.target.src && event.target.src.indexOf('.webp') > -1) {
        event.target.src = event.target.src.replace('.webp', '')
      } else {
        event.target.src =
          type === 'female'
            ? this.defaultImageAvatarF
            : this.defaultImageAvatarM
      }
    },
    getDefaultImgProfile(event, type) {
      if (event.target.src && event.target.src.indexOf('.webp') > -1) {
        event.target.src = event.target.src.replace('.webp', '')
      } else {
        event.target.src =
          type === 'brand' ? this.defaultImageBrand : this.defaultImageStore
      }
    },
    getDefaultCoverProfile(event, type) {
      if (event.target.src && event.target.src.indexOf('.webp') > -1) {
        event.target.src = event.target.src.replace('.webp', '')
      } else {
        event.target.src =
          type === 'brand' ? this.defaultCoverBrand : this.defaultCoverStore
      }
    },
    getDefaultLogoCC(event) {
      if (event.target.src && event.target.src.indexOf('.webp') > -1) {
        event.target.src = event.target.src.replace('.webp', '')
      } else {
        event.target.src = this.defaultImageCC
      }
    },

    categorySEOLogoImage (categoryFiles) {
      let urlCategorySEOLogoImg = process.env.URL + '/default-og-image.jpg'

      const categoryLogoImage = categoryFiles.filter((file) => {
        return file.name === 'logo'
      })

      if (categoryLogoImage.length > 0) {
        const categoryLogoImageThumbnail = categoryLogoImage.filter((file) => {
          return file.variant === 'thumbnail'
        })

        if (categoryLogoImageThumbnail.length > 0) {
          urlCategorySEOLogoImg = categoryLogoImageThumbnail[0].value
        } else {
          urlCategorySEOLogoImg = categoryLogoImage[0].value
        }
      }

      return urlCategorySEOLogoImg
    },

    categoryMetaKeyword (categoryMetaKeywords, categoryName = '') {
      let categoryMetaKeyword = ''

      if (Array.isArray(categoryMetaKeywords)) {
        if (categoryMetaKeywords.length === 1 && categoryMetaKeywords[0].trim() !== '') {
          categoryMetaKeyword = categoryMetaKeywords.map(keyword => keyword.trim()).join(',')
        } else {
          const categoryNameArray = categoryName.trim().split(' ')

          for (let i = 1; i < categoryNameArray.length; i++) {
            for (let j = 0; j <= i; j++) {
              categoryMetaKeyword += categoryNameArray[j]
              if (j < i) { categoryMetaKeyword += ' ' }
            }

            if (i < categoryNameArray.length - 1) { categoryMetaKeyword += ',' }
          }
        }
      }

      return categoryMetaKeyword.toLowerCase()
    }
  }
}
